<template>
    <article class="categories-sitemap-index">
        <!-- Category 1 -->
        <section
            v-for="(category, categoryKey) in sitemap"
            :key="categoryKey"
            class="categories-sitemap-index__parent-category"
        >
            <NuxtLink :to="'/' + $lang.routes.categories + '/' + category.slug"
                ><h2>{{ category.name }}</h2></NuxtLink
            >
            <article
                v-if="category.children && category.children.length"
                class="categories-sitemap-index__children"
            >
                <!-- Category 2 -->
                <section
                    v-for="(childrenA, childrenAKey) in category.children"
                    :key="childrenAKey"
                    class="categories-sitemap-index__children-category"
                >
                    <NuxtLink :to="'/' + $lang.routes.categories + '/' + childrenA.slug"
                        ><h3>{{ childrenA.name }}</h3></NuxtLink
                    >
                    <article
                        v-if="childrenA.children && childrenA.children.length"
                        class="categories-sitemap-index__children-category-grid"
                    >
                        <!-- Category 3 -->
                        <li
                            v-for="(childrenB, childrenBKey) in childrenA.children"
                            :key="childrenBKey"
                            class="categories-sitemap-index__children-category-grid-item"
                        >
                            <NuxtLink :to="'/' + $lang.routes.categories + '/' + childrenB.slug">{{
                                childrenB.name
                            }}</NuxtLink>
                        </li>
                    </article>
                </section>
            </article>
        </section>
    </article>
</template>

<script lang="ts" setup>
type SitemapCategory = {
    children: SitemapCategory[]
    name: string
    slug: string
}

const { buildHeaders, baseURL, endpoints } = useApiConfig()

const { data: responseData, error } = await useAsyncData('categories-sitemap-data', () => {
    return $fetch<SitemapCategory[]>(endpoints.pages.siteMap.categories, {
        headers: buildHeaders(),
        method: 'GET',
        baseURL,
    }).catch((e) => e.data || false)
})

if (error.value || !responseData.value || responseData.value.error) {
    throw createError({
        statusCode: responseData.value?.feedback === 'resource_not_found' ? 404 : 500,
        message:
            responseData.value?.feedback === 'resource_not_found' ? 'Página no encontrada' : 'Algo salió mal',
    })
}

const sitemap = ref(responseData.value) as Ref<SitemapCategory[]>
</script>

<style lang="postcss">
.categories-sitemap-index {
    a {
        @apply lg:hover:underline;
    }
    &__parent-category {
        @apply py-4 sm:py-6 lg:py-8;
        h2 {
            @apply block text-2xl font-semibold;
        }
        &:not(:last-of-type) {
            @apply border-b border-gray-200;
        }
    }
    &__children-category {
        @apply mt-3;
        h3 {
            @apply text-lg font-medium;
        }
        &-grid {
            @apply mb-6 mt-3 grid gap-5 leading-none xs:grid-cols-2 sm:grid-cols-3 lg:grid-cols-4;
            &-item {
                @apply list-none;
            }
        }
    }
}
</style>
